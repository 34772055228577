import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';

const CardTable = ({ cardData, removeCard, handleDownload }) => {
    const handleDownloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(cardData.map((card) => ({
            'Bank Name': card.selectedBank,
            'Card Name': card.selectedCard,
            'Approval Date': card.applicationApprovalDate,
            'Expiry Date': card.expiryDate,
            'Annual Fee': card.annualFee,
            'Annual Fee Waiver': card.annualFeeWaiver,
            'Annual Fees Due Date': card.annualFeesDueDate,
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Credit Card Details');
        XLSX.writeFile(wb, 'CreditCardDetails.xlsx');

        handleDownload();
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Bank Name</TableCell>
                            <TableCell>Card Name</TableCell>
                            <TableCell>Approval Date</TableCell>
                            <TableCell>Expiry Date</TableCell>
                            <TableCell>Annual Fee</TableCell>
                            <TableCell>Annual Fee Waiver</TableCell>
                            <TableCell>Annual Fees Due Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cardData.map((card, index) => (
                            <TableRow key={index}>
                                <TableCell>{card.selectedBank}</TableCell>
                                <TableCell>{card.selectedCard}</TableCell>
                                <TableCell>{card.applicationApprovalDate}</TableCell>
                                <TableCell>{card.expiryDate}</TableCell>
                                <TableCell>{card.annualFee}</TableCell>
                                <TableCell>{card.annualFeeWaiver}</TableCell>
                                <TableCell>{card.annualFeesDueDate}</TableCell>
                                <TableCell>
                                    <IconButton
                                        edge="end"
                                        color="error"
                                        aria-label="delete"
                                        onClick={() => removeCard(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {cardData.length > 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadExcel}
                    style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    Download as Excel
                </Button>
            )}
        </Box>
    );
};

export default CardTable;
