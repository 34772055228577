import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import ReactGA from 'react-ga4';

const FileUpload = ({ addCards }) => {
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            const validCards = [];
            for (const row of worksheet) {
                const {
                    'Bank Name': selectedBank,
                    'Card Name': selectedCard,
                    'Approval Date': applicationApprovalDate,
                    'Expiry Date': expiryDate,
                    'Annual Fee': annualFee,
                    'First Year Waiver': firstYearWaiver,
                } = row;

                if (selectedBank && selectedCard && applicationApprovalDate && expiryDate && annualFee && firstYearWaiver) {
                    validCards.push({
                        selectedBank,
                        selectedCard,
                        applicationApprovalDate,
                        expiryDate,
                        annualFee,
                        firstYearWaiver,
                    });
                } else {
                    alert('Invalid data found in the file. Please make sure all fields are filled correctly.');
                    return;
                }
            }

            ReactGA.event({
                category: 'User',
                action: 'uploaded_excel',
                hostname: window.location.hostname
            });

            addCards(validCards);
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <div>
            <input
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleFileUpload}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                    Upload Excel
                </Button>
            </label>
        </div>
    );
};

export default FileUpload;
