import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, AppBar, Toolbar, IconButton, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import CardForm from './CardForm';
import CardTable from './CardTable';
import FileUpload from './FileUpload';
import ReactGA from 'react-ga4';
import './App.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#F4A261', // Orange color
        },
        secondary: {
            main: '#2A9D8F', // Blue color
        },
        background: {
            default: '#FAFAFA', // Light background
        },
        error: {
            main: '#E76F51', // Pink color
        },
        text: {
            primary: '#264653', // Dark text
            secondary: '#2A9D8F', // Light grey text
        },
    },
    typography: {
        h4: {
            fontWeight: 700,
        },
    },
});

ReactGA.initialize('G-REH39SMJEC');
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/creditmate"});

const App = () => {
    const [cards, setCards] = useState([]);
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        fetch('/cards.json')
            .then(response => response.json())
            .then(data => setCards(data.banks))
            .catch(error => console.error('Error fetching card names:', error));
    }, []);

    const addCard = (card) => {
        ReactGA.event({
            category: 'User',
            action: 'added_credit_card',
            label: `${card.selectedBank} - ${card.selectedCard}`,
            hostname: window.location.hostname
        });
        setCardData([...cardData, card]);
    };

    const addCards = (newCards) => {
        ReactGA.event({
            category: 'User',
            action: 'uploaded_excel',
            hostname: window.location.hostname
        });
        setCardData([...cardData, ...newCards]);
    };

    const removeCard = (index) => {
        const newCardData = [...cardData];
        newCardData.splice(index, 1);
        setCardData(newCardData);
    };

    const handleDownload = () => {
        ReactGA.event({
            category: 'User',
            action: 'downloaded_excel',
            hostname: window.location.hostname
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        CreditMate by TravelHackingGuru
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="instagram"
                        href="https://instagram.com/travelhackingguru"
                        target="_blank"
                    >
                        <InstagramIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md">
                <Box mt={2}>
                    <Typography variant="h5" component="div" gutterBottom align="center" color="textSecondary">
                        The ultimate tool to manage and track your credit cards effortlessly.
                    </Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h4" component="h1" gutterBottom color="textPrimary">
                        Credit Card Details
                    </Typography>
                    <CardForm cards={cards} addCard={addCard} />
                    <Grid container justifyContent="center" mt={2}>
                        <FileUpload addCards={addCards} />
                    </Grid>
                    <CardTable cardData={cardData} removeCard={removeCard} handleDownload={handleDownload} />
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default App;
