import React, { useState } from 'react';
import { TextField, Button, MenuItem, Grid, Typography } from '@mui/material';

const menuProps = {
    PaperProps: {
        style: {
            backgroundColor: '#FFFFFF',
            color: '#264653',
        },
    },
};

const CardForm = ({ cards, addCard }) => {
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedCard, setSelectedCard] = useState('');
    const [applicationMonth, setApplicationMonth] = useState('');
    const [applicationYear, setApplicationYear] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');

    const handleBankChange = (e) => {
        setSelectedBank(e.target.value);
        setSelectedCard('');
    };

    const calculateDueDate = (approvalMonth, approvalYear, annualFeeWaiver) => {
        const approvalDate = new Date(approvalYear, approvalMonth - 1);
        let dueDate;

        switch (annualFeeWaiver) {
            case 'N/A':
                return 'Free';
            case 'Yes':
                dueDate = new Date(approvalDate.setFullYear(approvalDate.getFullYear() + 2));
                break;
            case 'Yes(2yrs)':
                dueDate = new Date(approvalDate.setFullYear(approvalDate.getFullYear() + 3));
                break;
            case 'Yes(3yrs)':
                dueDate = new Date(approvalDate.setFullYear(approvalDate.getFullYear() + 4));
                break;
            case 'No':
                dueDate = new Date(approvalDate.setFullYear(approvalDate.getFullYear() + 1));
                break;
            default:
                return '';
        }
        return `${String(dueDate.getMonth() + 1).padStart(2, '0')}/${dueDate.getFullYear()}`;
    };

    const handleAddRow = () => {
        if (!selectedBank || !selectedCard || !applicationMonth || !applicationYear || !expiryMonth || !expiryYear) {
            alert('Please fill in all fields before adding a new row.');
            return;
        }

        const applicationDate = new Date(`${applicationYear}-${applicationMonth}-01`);
        const expiryDate = new Date(`${expiryYear}-${expiryMonth}-01`);

        if (expiryDate < applicationDate) {
            alert('Expiry date cannot be before the application approval date.');
            return;
        }

        const selectedCardDetails = selectedBankCards.find(card => card.name === selectedCard);
        const annualFeesDueDate = calculateDueDate(applicationMonth, applicationYear, selectedCardDetails.annualFeeWaiver);

        addCard({
            selectedBank,
            selectedCard,
            applicationApprovalDate: `${applicationMonth}/${applicationYear}`,
            expiryDate: `${expiryMonth}/${expiryYear}`,
            annualFee: selectedCardDetails.annualFee,
            annualFeeWaiver: selectedCardDetails.annualFeeWaiver,
            annualFeesDueDate,
        });
        setSelectedBank('');
        setSelectedCard('');
        setApplicationMonth('');
        setApplicationYear('');
        setExpiryMonth('');
        setExpiryYear('');
    };

    const selectedBankCards = cards.find(bank => bank.bankName === selectedBank)?.cards || [];

    const months = Array.from({ length: 12 }, (v, k) => k + 1).map(month => month.toString().padStart(2, '0'));
    const yearsFrom2014 = Array.from({ length: new Date().getFullYear() - 2014 + 1 }, (v, k) => (2014 + k).toString());
    const years = Array.from({ length: 30 }, (v, k) => (new Date().getFullYear() + k).toString());

    const getApprovalMonths = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() is 0-based

        if (applicationYear === currentYear.toString()) {
            return months.filter(month => parseInt(month, 10) <= currentMonth);
        }

        return months;
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Select Bank and Card
                </Typography>
            </Grid>
            <Grid item xs={12} md={2.5}>
                <TextField
                    select
                    label="Bank Name"
                    value={selectedBank}
                    onChange={handleBankChange}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Bank</MenuItem>
                    {cards.map((bank) => (
                        <MenuItem key={bank.bankName} value={bank.bankName}>{bank.bankName}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={2.5}>
                <TextField
                    select
                    label="Card Name"
                    value={selectedCard}
                    onChange={(e) => setSelectedCard(e.target.value)}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Card</MenuItem>
                    {selectedBankCards.map((card) => (
                        <MenuItem key={card.id} value={card.name}>{card.name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Application Approval Details
                </Typography>
            </Grid>
            <Grid item xs={12} md={1.5}>
                <TextField
                    select
                    label="Approval Month"
                    value={applicationMonth}
                    onChange={(e) => setApplicationMonth(e.target.value)}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Month</MenuItem>
                    {getApprovalMonths().map((month) => (
                        <MenuItem key={month} value={month}>{month}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={1.5}>
                <TextField
                    select
                    label="Approval Year"
                    value={applicationYear}
                    onChange={(e) => setApplicationYear(e.target.value)}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Year</MenuItem>
                    {yearsFrom2014.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Expiry Details
                </Typography>
            </Grid>
            <Grid item xs={12} md={1.5}>
                <TextField
                    select
                    label="Expiry Month"
                    value={expiryMonth}
                    onChange={(e) => setExpiryMonth(e.target.value)}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Month</MenuItem>
                    {months.map((month) => (
                        <MenuItem key={month} value={month}>{month}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={1.5}>
                <TextField
                    select
                    label="Expiry Year"
                    value={expiryYear}
                    onChange={(e) => setExpiryYear(e.target.value)}
                    fullWidth
                    required
                    SelectProps={{ MenuProps: menuProps }}
                >
                    <MenuItem value="" disabled>Select Year</MenuItem>
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={1}>
                <Button variant="contained" color="primary" onClick={handleAddRow} fullWidth style={{minWidth: '48px', height: '48px'}}>
                    +
                </Button>
            </Grid>
        </Grid>
    );
};

export default CardForm;
